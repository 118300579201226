import axios from 'axios'

export default class QrcodeAPI {
  constructor() {
    this.api_token = null
    this.client = null
    this.api_url = 'https://qrlyf-qrcode.p.rapidapi.com/api/v2'
  }
  init = () => {
    this.api_token = null
    let headers = {
      Accept: 'application/json',
      'X-RapidAPI-Key': '5572193e18msh699c52a6b9f9812p11a59ajsn502a8c932884',
      'X-RapidAPI-Host': 'qrlyf-qrcode.p.rapidapi.com'
    }
    if (this.api_token) {
      headers.Authorization = `Bearer ${this.api_token}`
    }
    this.client = axios.create({ baseURL: this.api_url, timeout: 31000, headers: headers })
    return this.client
  }
  // getUserList = (params) => {
  //   return this.init().get("/users", {params: params});
  // };
  // addNewUser = (data) => {
  //   return this.init().post("/users", data);
  // };
  generateQR = (data) => {
    return this.init().post('/qrlyf/generate', data, { responseType: 'blob' })
  }
  uploadLogo = (data) => {
    return this.init().post('/uploadlogo', data)
  }
}
