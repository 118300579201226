import React from 'react'
import Header from './Header'
// import Footer from './Footer'
import PropTypes from 'prop-types'

const AppBody = (props) => {
  return (
    <>
      <Header />
      {/* <body className="d-flex flex-column h-100"> */}
      <main className="flex-shrink-0">{props.children}</main>
      {/* <Footer /> */}
      {/* </body> */}
    </>
  )
}

AppBody.propTypes = {
  children: PropTypes.node.isRequired
}
export default AppBody
