import React, { useRef, useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from '../FirebaseAuth'

const Register = () => {
  const auth = useAuth()
  const [error, setError] = useState('')
  const [pageLoading, setPageLoading] = useState(false)
  const [loadingAuth, setLoadingAuth] = useState(false)
  const [loadingSignup, setLodingSignup] = useState(false)
  const userEmail = useRef(null)
  const userPassword = useRef(null)
  const confirmPassword = useRef(null)
  const navigate = useNavigate()

  useEffect(() => {
    const checkAuth = async () => {
      const sleep = (ms) => {
        setPageLoading(true)
        return new Promise((resolve) => setTimeout(resolve, ms))
      }

      await sleep(5000)
      if (auth.user) {
        navigate('/dashboard')
      }
      console.log(auth)
      setPageLoading(false)
    }
    checkAuth()
  }, [auth.user])

  const handleSubmit = async (event) => {
    event.preventDefault()
    setLodingSignup(true)
    if (confirmPassword.current.value !== userPassword.current.value) {
      setError('Password not match')
      setLoadingAuth(false)
    } else {
      await auth
        .signup(userEmail.current.value, userPassword.current.value)
        .then(() => {
          setLodingSignup(false)
          navigate('/dashboard')
        })
        .catch((err) => {
          setError(err)
          setLodingSignup(false)
          console.log(err)
        })
    }
    event.target.reset()
  }

  const handleGoogleAuth = async () => {
    setLoadingAuth(true)
    await auth.googleSignInRedirect()
  }

  return (
    <>
      <div className="container">
        <div className="row pb-5 align-items-center justify-content-center">
          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}
          {pageLoading && (
            <div className="pt-5 text-center">
              <span
                className="spinner-border spinner-border-lg"
                role="status"
                aria-hidden="true"></span>
              <br />
              Loading...
            </div>
          )}
          {!pageLoading && (
            <>
              <div className="pt-5 col-lg-4 col-xl-4 col-xxl-3">
                <div className="card custom-shadow">
                  <div className="card-body">
                    <h5 className="card-title lead">Sign Up</h5>
                    <form onSubmit={handleSubmit}>
                      <div className="mb-3">
                        <label className="form-label">Email</label>
                        <input
                          type="email"
                          ref={userEmail}
                          className="form-control"
                          placeholder="user@example.com"
                          disabled={loadingSignup}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Password</label>
                        <input
                          type="password"
                          className="form-control"
                          ref={userPassword}
                          placeholder="12345678"
                          disabled={loadingSignup}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Confirm Password</label>
                        <input
                          type="password"
                          className="form-control"
                          ref={confirmPassword}
                          placeholder="12345678"
                          disabled={loadingSignup}
                        />
                      </div>
                      <div className="d-grid gap-2">
                        <button type="submit" className="btn btn-dark" disabled={loadingSignup}>
                          {loadingSignup && (
                            <>
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"></span>
                              Loading...
                            </>
                          )}
                          {!loadingSignup && <>Sign Up</>}
                        </button>
                      </div>
                    </form>
                    <hr />
                    <p>Register with another provider</p>
                    <div className="d-grid gap-2">
                      <button
                        className="btn btn-outline-dark"
                        onClick={handleGoogleAuth}
                        type="button"
                        disabled={loadingAuth}>
                        {loadingAuth && (
                          <>
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"></span>
                            Loading...
                          </>
                        )}
                        {!loadingAuth && (
                          <span>
                            <i className="bi bi-google"></i> Google
                          </span>
                        )}
                      </button>
                    </div>
                    <p>
                      Have an account? <Link to="/login">Login</Link>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-5 col-xxl-6 d-none d-xl-block text-center">
                <img
                  className="img-fluid rounded-3 my-5"
                  src="/assets/3.svg"
                  alt="signup-illustrations"
                />
              </div>
            </>
          )}
          {/* <div className="pt-4 col-lg-4 col-xl-4 col-xxl-3">
            <div className="card custom-shadow">
              <div className="card-body">
                <h5 className="card-title lead">Sign Up</h5>
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label className="form-label">Email</label>
                    <input
                      type="email"
                      ref={userEmail}
                      className="form-control"
                      placeholder="user@example.com"
                      disabled={loadingSignup}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Password</label>
                    <input
                      type="password"
                      className="form-control"
                      ref={userPassword}
                      placeholder="12345678"
                      disabled={loadingSignup}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Confirm Password</label>
                    <input
                      type="password"
                      className="form-control"
                      ref={confirmPassword}
                      placeholder="12345678"
                      disabled={loadingSignup}
                    />
                  </div>
                  <div className="d-grid gap-2">
                    <button type="submit" className="btn btn-dark" disabled={loadingSignup}>
                      {loadingSignup && (
                        <>
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"></span>
                          Loading...
                        </>
                      )}
                      {!loadingSignup && <>Sign Up</>}
                    </button>
                  </div>
                </form>
                <hr />
                <p>Register with another provider</p>
                <div className="d-grid gap-2">
                  <button
                    className="btn btn-outline-dark"
                    onClick={handleGoogleAuth}
                    type="button"
                    disabled={loadingAuth}>
                    {loadingAuth && (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"></span>
                        Loading...
                      </>
                    )}
                    {!loadingAuth && (
                      <span>
                        <i className="bi bi-google"></i> Google
                      </span>
                    )}
                  </button>
                </div>
                <p>
                  Have an account? <Link to="/login">Login</Link>
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-5 col-xxl-6 d-none d-xl-block text-center">
            <img
              className="img-fluid rounded-3 my-5"
              src="/assets/3.svg"
              alt="signup-illustrations"
            />
          </div> */}
        </div>
      </div>
    </>
  )
}

export default Register
