import React, { useRef, useState } from 'react'
import QrcodeAPI from '../../api/QrcodeAPI'

const QrStaticGen = () => {
  // const [loading, isLoading] = useState(false)
  const [upload, isUpload] = useState(false)
  const [generate, isGenerate] = useState(false)
  const [download, isDownload] = useState(false)

  const [qrImg, setQrImg] = useState('')
  const [qrApiBody, setQrApiBody] = useState('')
  const [qrLogo, setQrLogo] = useState('')
  const [error, setError] = useState('')

  const contentRef = useRef(null)
  const colorBgRef = useRef(null)
  const colorFgRef = useRef(null)
  const browseFile = useRef(null)

  const api = new QrcodeAPI()
  const formData = new FormData()
  const reader = new FileReader()

  // generate qr with or without logo
  const handleSubmit = async (event) => {
    event.preventDefault()
    isGenerate(true)

    const param = {
      data: contentRef.current.value,
      logo: {
        logo_path: qrLogo
      },
      config: {
        auto: true,
        version: 4,
        error_correction: 'H',
        box_size: 40,
        border: 4
      },
      design: {
        qr_colour: colorBgRef.current.value,
        bg_colour: colorFgRef.current.value
      },
      option: {
        file_type: 'png'
      }
    }
    setQrApiBody(param)
    console.log('API Body', param)

    await api
      .generateQR(param)
      .then((res) => {
        if (res.status === 200) {
          const qrBlob = new Blob([res.data], { type: res.data.type })

          reader.readAsDataURL(qrBlob)
          reader.onloadend = () => {
            // convert file to base64 and get filename
            setQrImg({
              generateQr: reader.result
            })
          }
          isGenerate(false)
        }
      })
      .catch((err) => {
        setError({
          errCode: err.code,
          errMsg: err.message
        })
        isGenerate(false)
        console.log(err)
      })
  }

  // Upload logo
  const openFile = () => {
    browseFile.current?.click()
  }

  const uploadLogo = async (event) => {
    event.preventDefault()
    isUpload(true)
    console.log(browseFile.current.files[0].name)

    formData.append('file', browseFile.current.files[0], browseFile.current.files[0].name)

    reader.readAsDataURL(
      new Blob([browseFile.current.files[0]], { type: browseFile.current.files[0].type })
    )
    reader.onloadend = () => {
      setQrImg({ uploadLogo: reader.result })
    }

    await api
      .uploadLogo(formData)
      .then((res) => {
        if (res.status === 200) {
          setQrLogo(res.data.filename)
          console.log(setQrImg)
          isUpload(false)
        }
      })
      .catch((err) => {
        setError({
          errCode: err.code,
          errMsg: err.message
        })
      })
  }

  // Download logo base on file type
  const downloadQr = async (event) => {
    event.preventDefault()
    isDownload(true)
    const fileType = event.target.getAttribute('data-filetype')
    // update file type
    qrApiBody.option.file_type = fileType

    await api
      .generateQR(qrApiBody)
      .then((res) => {
        if (res.status === 200) {
          console.log(res)
          isDownload(false)
          var filename = ''
          const contentDisposition = res.headers['content-disposition']

          if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
            var matches = filenameRegex.exec(contentDisposition)
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, '')
            }
          }
          const link = document.createElement('a')
          const url = URL.createObjectURL(new Blob([res.data], { type: res.data.type }))

          link.href = url
          link.setAttribute('download', filename)
          link.click()
        }
      })
      .catch((err) => {
        console.log(err)
        isDownload(false)
        setError({
          errCode: err.code,
          errMsg: err.message
        })
      })
  }

  return (
    <>
      {/* prettier-ignore */}
      <div className="container my-5">
        {error && (
          <div className="alert alert-danger" role="alert">
            {error.errMsg} ({error.errCode})
          </div>
        )}
        <div className="card border-dark">
          <div className="card-body">
            <div className="row">
              <div className="col-md-6">
                <form>
                  <div className="mb-3">
                    <label className="form-label lead">
                      <i className="bi bi-pencil-square"></i> URL / text
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="http://url.com or long text"
                      ref={contentRef}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label lead">
                      <i className="bi bi-palette-fill"></i> Set Color
                    </label>
                    <div className="row">
                      <div className="col-md-6">
                        <label className="form-label">Foreground Color</label>
                        <input
                          type="color"
                          defaultValue="#000000"
                          className="form-control form-control-color"
                          title="Choose QR Foreground color"
                          ref={colorBgRef}
                        />
                      </div>
                      <div className="col-md-6">
                        <label className="form-label">Background Color</label>
                        <input
                          type="color"
                          defaultValue="#FFFFFF"
                          className="form-control form-control-color"
                          title="Choose QR Foreground color"
                          ref={colorFgRef}
                        />
                      </div>
                    </div>
                  </div>
                  {/* Logo upload */}
                  <div className="mb-3">
                    <label className="form-label lead">
                      <i className="bi bi-file-image"></i> Logo
                    </label>
                    <div className="row">
                      <div className="col-md-4 text-center">
                        <img
                          className="img-fluid"
                          src={!qrImg.uploadLogo ? '/assets/no-logo.png' : qrImg.uploadLogo}
                          height="250px"
                          width="250px"
                        />
                      </div>
                      <div className="col-md-3">
                        <label className="form-label"></label>
                        <div className="d-grid gap-2">
                          <input
                            type="file"
                            className="d-none"
                            ref={browseFile}
                            onChange={uploadLogo}
                            accept="image/png, image/jpeg"
                          />
                          <button
                            className="btn btn-primary"
                            onClick={openFile}
                            disabled={upload}
                            type="button"
                          >
                            {upload && (
                              <>
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                                &#x20; Uploading...
                              </>
                            )}
                            {!upload && (
                              <>
                                <i className="bi bi-upload"></i> Upload
                              </>
                            )}
                          </button>
                          <button className="btn btn-outline-danger" type="button">
                            <i className="bi bi-trash3-fill"></i> Delete
                          </button>
                        </div>
                      </div>
                      <div className="col-md-5">
                        <div className="alert alert-secondary" role="alert">
                          Accept PNG and JPEG type only. Max size 5 Mb.
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Generate Button */}
                  <div className="mb-3">
                    <div className="d-grid">
                      <button
                        className="btn btn-dark btn-lg"
                        onClick={handleSubmit}
                        disabled={generate}
                        type="button"
                      >
                        {generate && (
                          <>
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            />
                            &#x20; Generating...
                          </>
                        )}
                        {!generate && (
                          <>
                            <i className="bi bi-gear-fill"></i> Generate QR
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              {/* Output and download */}
              <div className="col-md-6">
                <p className="lead">
                  <i className="bi bi-qr-code"></i> Output
                </p>
                <div className="text-center">
                  <img
                    className="img-fluid"
                    src={!qrImg.generateQr ? '/assets/qrlyf-qrcode.png' : qrImg.generateQr}
                    height="320px"
                    width="320px"
                  />
                  <hr />
                  {/* Download Button */}
                  <p>
                    <i className="bi bi-download"></i> Download
                  </p>
                  <div className="d-grid gap-2 d-md-flex justify-content-md-center">
                    <button
                      disabled={download}
                      className="btn btn-dark"
                      data-filetype="png"
                      onClick={downloadQr}
                      type="button"
                    >
                      <i className="bi bi-filetype-png"></i> .PNG
                    </button>
                    <button
                      disabled={download}
                      className="btn btn-outline-dark"
                      data-filetype="pdf"
                      onClick={downloadQr}
                      type="button"
                    >
                      <i className="bi bi-filetype-pdf"></i> .PDF
                    </button>
                    {/* <button
                      disabled={download}
                      className="btn btn-outline-dark"
                      data-filetype="eps"
                      onClick={downloadQr}
                      type="button">
                      <i className="bi bi-card-image"></i> .EPS
                    </button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default QrStaticGen
