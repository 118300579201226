import React from 'react'
import { Link } from 'react-router-dom'

const Dashboard = () => {
  return (
    <>
      <div className="container my-5">
        <hr />
        <div className="row">
          <div className="col-lg-8 col-md-7 col-sm-8">
            <div className="input-group mb-3">
              <input type="text" className="form-control" placeholder="Search...." />
            </div>
          </div>
          <div className="col-lg-4 col-md-5 col-sm-4">
            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
              <Link className="btn btn-outline-dark" to="/qrtool/qr-static">
                <i className="bi bi-plus-circle"></i> Create QR
              </Link>
              <Link className="btn btn-outline-dark" to="qrtools">
                <i className="bi bi-qr-code-scan"></i> Scan QR
              </Link>
              {/* <button className="btn btn-outline-dark" type="button">
                <i className="bi bi-plus-circle"></i> Create QR
              </button>
              <button className="btn btn-outline-dark" type="button">
                <i className="bi bi-qr-code-scan"></i> Scan QR
              </button> */}
            </div>
          </div>
        </div>
        <div className="row pt-5">
          <div className="col-lg-4 col-md-6 my-2">
            <div className="card border-dark special-card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    <img className="img-fluid" src="https://via.placeholder.com/500" />
                  </div>
                  <div className="col-md-6">
                    <span className="badge bg-dark">Static</span>
                    <p className="pt-3 text-truncate">
                      <strong>Content</strong> <br />
                      <small>
                        This is content of QR code. very long text actually. huehuehuehue
                      </small>
                    </p>
                    {/* <p className="lead">Scan: 10</p> */}
                    <small className="text-muted">Last Edit: 29/12/2022</small>
                    {/* <a href="#" className="btn btn-primary">
                      Download
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 my-2">
            <div className="card border-dark special-card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    <img className="img-fluid" src="https://via.placeholder.com/500" />
                  </div>
                  <div className="col-md-6">
                    <span className="badge bg-success">Dynamic</span>
                    <p className="pt-3 text-truncate">
                      <strong>Content</strong> <br />
                      <small>
                        This is content of QR code. very long text actually. huehuehuehue
                      </small>
                    </p>
                    <p className="lead">Scan: 10</p>
                    <small className="text-muted">Last Edit: 29/12/2022</small>
                    {/* <a href="#" className="btn btn-primary">
                      Download
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Dashboard
