import React from 'react'

const Setting = () => {
  return (
    <>
      <div>
        <p>Setting</p>
      </div>
    </>
  )
}

export default Setting
