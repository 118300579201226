import React from 'react'
const Faq = () => {
  return (
    <>
      <section className="bg-dark py-5">
        <div className="container px-5 my-5">
          <h3 className="text-center text-white-50">FREQUENTLY ASKED QUESTIONS</h3>
          <div className="accordion">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#one"
                  aria-expanded="true"
                  aria-controls="collapseOne">
                  What is QR Codes?
                </button>
              </h2>
              <div
                id="one"
                className="accordion-collapse collapse"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  A QR code (an initialism for quick response code) is a type of matrix barcode (or
                  two-dimensional barcode) invented in 1994 by the Japanese automotive company Denso
                  Wave. A barcode is a machine-readable optical label that can contain information
                  about the item to which it is attached. In practice, QR codes often contain data
                  for a locator, identifier, or tracker that points to a website or application. QR
                  codes use four standardized encoding modes (numeric, alphanumeric, byte/binary,
                  and kanji) to store data efficiently; extensions may also be used.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#two"
                  aria-expanded="true"
                  aria-controls="collapseOne">
                  It is free?
                </button>
              </h2>
              <div
                id="two"
                className="accordion-collapse collapse"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  Yes, it is free for both personal and commercial use.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#three"
                  aria-expanded="false"
                  aria-controls="collapseTwo">
                  What are the differences between static and dynamic QR codes?
                </button>
              </h2>
              <div
                id="three"
                className="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  Static QR Codes are not editable and cannot be used for online retargeting.
                  Dynamic QR Codes, on the other hand, give you the flexibility to edit as many
                  times as you need and also allow for online retargeting.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#four"
                  aria-expanded="false"
                  aria-controls="collapseTwo">
                  Do my QR Codes expire?
                </button>
              </h2>
              <div
                id="four"
                className="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  <p>
                    No, they never expire. The QR Code always redirects you to the original URL in a
                    direct manner.
                  </p>
                  <p>
                    Dynamic QR codes in your free account will never expire, except you QR Code
                    yourself or the target URL is redirecting you to an expired website website
                    website
                  </p>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#five"
                  aria-expanded="false"
                  aria-controls="collapseTwo">
                  Is there a scan limit for the QR codes?
                </button>
              </h2>
              <div
                id="five"
                className="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  There is no limit and the created QR code will work forever. Scan it as many times
                  as you wish!
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#six"
                  aria-expanded="false"
                  aria-controls="collapseTwo">
                  Are you displaying ads or banners ?
                </button>
              </h2>
              <div
                id="six"
                className="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample">
                <div className="accordion-body">No, we do not display any ads in our platform.</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Faq
