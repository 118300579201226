import React from 'react'

const DansoWave = () => {
  return (
    <>
      <div className="text-center text-black p-2">
        QR Code is a registered trademark of DENSO WAVE INCORPORATED
      </div>
    </>
  )
}

export default DansoWave
