import React from 'react'
import { Link } from 'react-router-dom'

const Hero = () => {
  return (
    <>
      <header className="py-5 hero-custom">
        <div className="container px-5">
          <div className="row gx-5 align-items-center justify-content-center">
            <div className="col-lg-8 col-xl-7 col-xxl-6">
              <div className="my-5 text-center text-xl-start">
                <h1 className="display-5 fw-bolder text-black mb-2">
                  Everything is on one platform!
                </h1>
                <p className="lead fw-normal text-black-60 mb-4">
                  Unlimited statics and dynamic Qrcode for small to medium-sized businesses.
                </p>
                <div className="d-grid gap-3 d-sm-flex justify-content-sm-center justify-content-xl-start">
                  <Link className="btn btn-dark btn-lg px-4 me-sm-3" to="/quick-qrcode">
                    Quick QR
                  </Link>
                  <Link className="btn btn-outline-dark btn-lg px-4" to="/signup">
                    Sign Up
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-5 col-xxl-6 d-none d-xl-block text-center">
              <img
                className="img-fluid rounded-3 my-5"
                src="/assets/1.svg"
                alt="hero-illustrations"
              />
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export default Hero
