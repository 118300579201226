import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
  apiKey: 'AIzaSyChNEFacxUQxdJgffDj2hdPt_d6IomtDM0',
  authDomain: 'qrlyf-project.firebaseapp.com',
  projectId: 'qrlyf-project'
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

// Initialize Firebase Authentication and get a reference to the service
const firebaseAuth = getAuth(app)

export default firebaseAuth
