import React, { useState, useEffect, useContext, createContext } from 'react'

import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  confirmPasswordReset,
  onAuthStateChanged,
  sendEmailVerification,
  signInWithRedirect,
  GoogleAuthProvider,
  getRedirectResult
} from 'firebase/auth'
import firebaseAuth from './firebase/sdk'

const authContext = createContext()

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
// eslint-disable-next-line react/prop-types
export function ProvideAuth({ children }) {
  const auth = useProvideAuth()
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext)
}

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useState(null)
  // Wrap any Firebase methods we want to use making sure ...
  // ... to save the user to state.
  const signin = async (email, password) => {
    return await signInWithEmailAndPassword(firebaseAuth, email, password).then((response) => {
      console.log(response)
      setUser(response.user)
      return response.user
    })
  }
  const signup = async (email, password) => {
    return await createUserWithEmailAndPassword(firebaseAuth, email, password).then((response) => {
      sendEmailVerification(response.user)
      setUser(response.user)
      return response.user
    })
  }
  const signout = async () => {
    return await signOut(firebaseAuth).then(() => {
      setUser(false)
    })
  }
  const pswdReset = async (email) => {
    return await sendPasswordResetEmail(firebaseAuth, email).then(() => {
      return true
    })
  }
  const confrimPswd = async (code, password) => {
    return confirmPasswordReset(firebaseAuth, code, password).then(() => {
      return true
    })
  }

  const googleSignInRedirect = async () => {
    const provider = new GoogleAuthProvider()
    await signInWithRedirect(firebaseAuth, provider)
    return getRedirectResult(firebaseAuth).then((response) => {
      setUser(response.user)
      console.log(response)
      return response.user
    })
  }

  // const googleSignInRedirectResult = async () => {
  //   return getRedirectResult(firebaseAuth).then((response) => {
  //     console.log('app auth', response.user)
  //     setUser(response.user)
  //     return response.user
  //   })
  // }
  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, (user) => {
      if (user) {
        setUser(user)
      } else {
        setUser(false)
      }
    })
    // Cleanup subscription on unmount
    return () => unsubscribe()
  }, [])
  // Return the user object and auth methods
  return {
    user,
    signin,
    signup,
    signout,
    pswdReset,
    confrimPswd,
    googleSignInRedirect
    // googleSignInRedirectResult
  }
}
