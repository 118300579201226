import React from 'react'
import Feature from '../components/Feature'
import Hero from '../components/Heros'
import Faq from '../components/Faq'
import DansoWave from '../components/DansoWave'

const Index = () => {
  return (
    <>
      <Hero />
      <Feature />
      <Faq />
      <DansoWave />
    </>
  )
}

export default Index
