import React from 'react'
import { NavLink, Outlet } from 'react-router-dom'
const QrNav = () => {
  return (
    <>
      {/* prettier-ignore */}
      <div className="container px-5 my-5">
        <nav className="nav justify-content-center nav-pills">
          <NavLink
            to="qr-static"
            className={({ isActive }) => 'nav-link ' + (isActive ? 'active' : undefined)}
          >
            QR Static
          </NavLink>
          <NavLink
            to="qr-dynamic"
            className={({ isActive }) => 'nav-link ' + (isActive ? 'active' : undefined)}
          >
            QR Dynamic
          </NavLink>
          <NavLink to="qr-vcard" className="nav-link disabled">
            QR VCard
          </NavLink>
          <NavLink to="qr-wifi" className="nav-link disabled">
            QR Wifi
          </NavLink>
          <NavLink to="qr-shop" className="nav-link disabled">
            QR Shop
          </NavLink>
          <NavLink to="qr-menu" className="nav-link disabled">
            QR Menu
          </NavLink>
        </nav>
      </div>
      <Outlet />
    </>
  )
}

export default QrNav
