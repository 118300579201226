import React from 'react'
import { Outlet, NavLink } from 'react-router-dom'
// import './../../custom.css'

const UserNav = () => {
  return (
    <>
      {/* prettier-ignore */}
      <div className="container px-5 my-5">
        <nav className="nav justify-content-center nav-pills">
          <NavLink
            to="dashboard"
            className={({ isActive }) => 'nav-link ' + (isActive ? 'active' : undefined)}
          >
            Dashboard
          </NavLink>
          <NavLink
            to="statistic"
            className={({ isActive }) => 'nav-link ' + (isActive ? 'active' : undefined)}
          >
            Statistic
          </NavLink>
          <NavLink
            to="setting"
            className={({ isActive }) => 'nav-link ' + (isActive ? 'active' : undefined)}
          >
            Setting
          </NavLink>
        </nav>
      </div>
      <Outlet />
    </>
  )
}

export default UserNav
