import { Navigate, Outlet } from 'react-router-dom'
import { useAuth } from '../FirebaseAuth'

const PrivateRoutes = () => {
  const auth = useAuth()
  console.log(auth)
  return auth.user ? <Outlet /> : <Navigate to="/login" />
}

export default PrivateRoutes
