import React, { useRef, useEffect, useState } from 'react'
import { useAuth } from '../FirebaseAuth'
import { Link, useNavigate } from 'react-router-dom'

const Login = () => {
  const auth = useAuth()
  const navigate = useNavigate()
  const [error, setError] = useState('')
  const [pageLoading, setPageLoading] = useState(false)
  const [loadingAuth, setLoadingAuth] = useState(false)
  const [loadingLogin, setLoadingLogin] = useState(false)
  const userEmail = useRef(null)
  const userPassword = useRef(null)

  useEffect(() => {
    const checkAuth = async () => {
      const sleep = (ms) => {
        setPageLoading(true)
        return new Promise((resolve) => setTimeout(resolve, ms))
      }

      await sleep(5000)
      if (auth.user) {
        navigate('/dashboard')
      }
      console.log(auth)
      setPageLoading(false)
    }
    checkAuth()
  }, [auth.user])

  const handleSubmit = async (event) => {
    event.preventDefault()
    setLoadingLogin(true)
    await auth
      .signin(userEmail.current.value, userPassword.current.value)
      .then(() => {
        setLoadingLogin(false)
        navigate('/dashboard')
      })
      .catch((err) => {
        if (err.code === 'auth/wrong-password') {
          setError({
            errMsg: 'Invalid Email or Password, please try again.'
          })
        } else {
          setError({
            errMsg: err.message
          })
        }
        setLoadingLogin(false)
      })
    event.target.reset()
  }

  const handleGoogleAuth = async () => {
    setLoadingAuth(true)
    await auth.googleSignInRedirect().catch((err) => {
      setError({
        errMsg: err.message
      })
    })
  }
  return (
    <>
      <div className="container">
        <div className="row align-items-center justify-content-center">
          {error && (
            <div className="alert alert-danger" role="alert">
              {error.errMsg}
            </div>
          )}
          {pageLoading && (
            <div className="pt-5 text-center">
              <span
                className="spinner-border spinner-border-lg"
                role="status"
                aria-hidden="true"></span>
              <br />
              Loading...
            </div>
          )}
          {!pageLoading && (
            <>
              <div className="pt-5 col-lg-4 col-xl-4 col-xxl-3">
                <div className="card custom-shadow">
                  <div className="card-body">
                    <h5 className="card-title lead">Login</h5>
                    <form onSubmit={handleSubmit}>
                      <div className="mb-3">
                        <label className="form-label">Email</label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="user@example.com"
                          ref={userEmail}
                          disabled={loadingLogin}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Password</label>
                        <input
                          type="password"
                          className="form-control"
                          ref={userPassword}
                          disabled={loadingLogin}
                        />
                      </div>
                      <div className="d-grid gap-2">
                        <button type="submit" className="btn btn-dark" disabled={loadingLogin}>
                          {loadingLogin && (
                            <>
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"></span>
                              Loading...
                            </>
                          )}
                          {!loadingLogin && <>Login</>}
                        </button>
                      </div>
                    </form>
                    <hr />
                    <p>Login with another provider</p>
                    <div className="d-grid gap-2">
                      <button
                        className="btn btn-outline-dark"
                        onClick={handleGoogleAuth}
                        type="button"
                        disabled={loadingAuth}>
                        {loadingAuth && (
                          <>
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"></span>
                            Loading...
                          </>
                        )}
                        {!loadingAuth && (
                          <span>
                            <i className="bi bi-google"></i> Google
                          </span>
                        )}
                      </button>
                    </div>
                    <p>
                      Don&apos;t have an account? <Link to="/signup">Sing Up</Link>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-5 col-xxl-6 d-none d-xl-block text-center">
                <img
                  className="img-fluid rounded-3 my-5"
                  src="/assets/2.svg"
                  alt="login-illustrations"
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default Login
