import React from 'react'
import ReactDOM from 'react-dom/client'
// import './index.css'
import './custom.css'
import Index from './pages/Index'
import Pricing from './pages/Pricing'
import QrGen from './components/QrGen'
import reportWebVitals from './reportWebVitals'
import AppBody from './components/layout/AppBody'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Login from './auth/Login'
import Signup from './auth/Signup'
import NotFound from './pages/NotFound'
import Dashboard from './pages/user/Dashboard'
import UserNav from './pages/user/UserNav'
import Setting from './pages/user/Setting'
import QrNav from './components/QrNav'
import QrStaticGen from './pages/qrlyf/QrStaticGen'
import { ProvideAuth } from './FirebaseAuth'
import PrivateRoutes from './components/PrivateRoutes'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  // <React.StrictMode>
  // </React.StrictMode>
  <ProvideAuth>
    <HelmetProvider>
      <BrowserRouter>
        <AppBody>
          <Routes>
            {/* Public */}
            <Route index path="/" element={<Index />} />
            <Route index path="/pricing" element={<Pricing />} />
            <Route index path="/quick-qrcode" element={<QrGen />} />
            <Route index path="/login" element={<Login />} />
            <Route index path="/signup" element={<Signup />} />

            <Route element={<PrivateRoutes />}>
              <Route path="/" element={<UserNav />}>
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="setting" element={<Setting />} />
              </Route>
              <Route path="/qrtool" element={<QrNav />}>
                <Route path="qr-static" element={<QrStaticGen />} />
              </Route>
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </AppBody>
      </BrowserRouter>
    </HelmetProvider>
  </ProvideAuth>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
