import React from 'react'
// import React from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../FirebaseAuth'

const Navbar = () => {
  // const [login, isLogin] = useState(false)
  const auth = useAuth()

  // if (!auth.user) {
  //   isLogin(true)
  // } else {
  //   isLogin(false)
  // }

  const handleLogout = async () => {
    await auth.signout()
  }

  return (
    <>
      {/* prettier-ignore */}
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container px-5">
          <Link className='navbar-brand' to="/">
            <img src="assets/logo-qrlyf.png" className="img-fluid" height="50px" width="50px"/> QRLYF
          </Link>
          <button className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link className='nav-link' to="/quick-qrcode">
                  Quick Qrcode
                </Link>
              </li>
              <li className="nav-item">
                <Link className='nav-link' to="/pricing">
                  Pricing
                </Link>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="https://blog.qrlyf.com">
                  Blog
                </a>
                {/* <Link className='nav-link' href="https://blog.qrlyf.com">
                  Blog
                </Link> */}
              </li>
              {!auth.user ? (
                <>
                  <li className="nav-item">
                    <Link className="nav-link" to="/login">
                      Login
                    </Link>
                  </li>
                  <li className="nav-item">
                  <Link className="nav-link" to="/signup">
                      Sign Up
                    </Link>
                  </li>
                </>
              ): (
                <li className="nav-item">
                  <Link className="nav-link" onClick={handleLogout} to="/login">
                    Logout
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </>
  )
}

export default Navbar
