import React from 'react'
import PropTypes from 'prop-types'

const QrGenOut = (props) => {
  console.log(props)
  if (props.status === true) {
    // eslint-disable-next-line no-undef
    const bsModal = new bootstrap.Modal('#qrResult', {
      backdrop: 'static',
      keyboard: false
    })
    bsModal.show()
  }

  const hideModal = () => {
    // eslint-disable-next-line no-undef
    const bsModal = bootstrap.Modal.getInstance('#qrResult')
    bsModal.hide()
  }

  return (
    <>
      {/* prettier-ignore */}
      <div className="modal fade" id="qrResult" aria-labelledby="qrResultModel" aria-hidden="true">
        <div className="modal-dialog modal-fullscreen-sm-down">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Complete
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <img
                src={props.data.base64}
                alt={props.data.filename}
                className="rounded mx-auto d-block"
                width="300px"
                height="300px"
              />
              <dl className="row">
                <dt className="col-sm-5">New Filename</dt>
                <dd className="col-sm-7">{props.data.filename}</dd>
                <dt className="col-sm-5">Content</dt>
                <dd className="col-sm-7">{props.data.content}</dd>
              </dl>
              <div className="text-center">
                <a
                  type="button"
                  className="btn btn-success"
                  href={props.data.base64}
                  // eslint-disable-next-line react/no-unknown-property
                  download={props.data.filename}
                >
                  <i className="bi bi-download"></i> Download File
                </a>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={hideModal}>
                Close
              </button>
              <button type="button" className="btn btn-primary">
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

QrGenOut.propTypes = {
  data: PropTypes.object,
  status: PropTypes.bool
}

export default QrGenOut
