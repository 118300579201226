const Feature = () => {
  return (
    <>
      <section className="py-5" id="features">
        <div className="container px-5 my-5">
          <div className="row gx-5">
            <div className="col-lg-4 mb-5 mb-lg-0">
              <h2 className="fw-bolder mb-0">Main feature</h2>
            </div>
            <div className="col-lg-8">
              <div className="row gx-5 row-cols-1 row-cols-md-2">
                <div className="col mb-5 h-100">
                  <div className="feature bg-dark bg-gradient text-white rounded-3 mb-3">
                    <i className="bi bi-lightning-charge"></i>
                  </div>
                  <h2 className="h5">Quick QR</h2>
                  <p className="mb-0">
                    Generate QRcode without extra configuration or design. Just a standard QR code.
                  </p>
                </div>
                <div className="col mb-5 h-100">
                  <div className="feature bg-dark bg-gradient text-white rounded-3 mb-3">
                    <i className="bi bi-qr-code"></i>
                  </div>
                  <h2 className="h5">Static QR</h2>
                  <p className="mb-0">
                    Customize your Qr code design based on your themes and don&apos;t forget add
                    organization logo!
                  </p>
                </div>
                <div className="col mb-5 h-100">
                  <div className="feature bg-dark bg-gradient text-white rounded-3 mb-3">
                    <i className="bi bi-qr-code-scan"></i>
                  </div>
                  <h2 className="h5">Dynamic QR</h2>
                  <p className="mb-0">
                    Track unlimited scan and customize your url <code>/jhwxce32</code> to{' '}
                    <code>/MiniMarket2022</code>
                  </p>
                </div>
                <div className="col mb-5 h-100">
                  <div className="feature bg-dark bg-gradient text-white rounded-3 mb-3">
                    <i className="bi bi-star"></i>
                  </div>
                  <h2 className="h5">Coming Soon</h2>
                  <p className="mb-0">More features are coming soon!</p>
                </div>
                {/* <div className="col mb-5 h-100">
                  <div className="feature bg-dark bg-gradient text-white rounded-3 mb-3">
                    <i className="bi bi-list-stars"></i>
                  </div>
                  <h2 className="h5">Catalog</h2>
                  <p className="mb-0">
                    Share your services/products/menu list dynamically or statically.
                  </p>
                </div>
                <div className="col mb-5 h-100">
                  <div className="feature bg-dark bg-gradient text-white rounded-3 mb-3">
                    <i className="bi bi-folder2"></i>
                  </div>
                  <h2 className="h5">Share File</h2>
                  <p className="mb-0">Share a file in public without using a lengthy URL.</p>
                </div>
                <div className="col mb-5 h-100">
                  <div className="feature bg-dark bg-gradient text-white rounded-3 mb-3">
                    <i className="bi bi-blockquote-left"></i>
                  </div>
                  <h2 className="h5">Online Feedback</h2>
                  <span className="badge bg-info">Coming Soon</span>
                  <p className="mb-0">Collect customer feedback quickly and easily.</p>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Feature
