import React, { useRef, useState } from 'react'
import QrGenOut from './QrGenOut'
import QrcodeAPI from '../api/QrcodeAPI'
import Faq from './Faq'
import DansoWave from './DansoWave'
const QrGen = () => {
  const qrValue = useRef(null)
  const api = new QrcodeAPI()
  const [loading, isLoading] = useState(false)
  const [ready, isReady] = useState(false)
  const [data, setData] = useState('')
  const [error, setError] = useState('')

  const handleSubmit = async (event) => {
    event.preventDefault()
    isLoading(true)

    if (qrValue.current.value === '') {
      setError({
        errorMessage: 'Please input something'
      })
      isLoading(false)
    }
    let param = {
      data: qrValue.current.value,
      config: {
        auto: true,
        version: 3,
        error_correction: 'M',
        box_size: 30,
        border: 4
      },
      design: {
        qr_colour: '#000000',
        bg_colour: '#ffffff'
      },
      option: {
        file_type: 'png'
      }
    }
    await api
      .generateQR(param)
      .then((res) => {
        if (res.status === 200) {
          var filename = ''
          const contentDisposition = res.headers['content-disposition']
          const newBlob = new Blob([res.data], { type: res.data.type })
          const reader = new FileReader()

          if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
            var matches = filenameRegex.exec(contentDisposition)
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, '')
            }
          }
          reader.readAsDataURL(newBlob)
          reader.onloadend = () => {
            setData({
              base64: reader.result,
              filename: filename,
              content: qrValue.current.value
            })
            isReady(true)
            isLoading(false)
          }
          console.log(res)
          // stop loading
          // show modal/output
          // show image output
          // show download button
        }
      })
      .catch((err) => {
        console.log(err)
        setError({
          errorMessage: err.message,
          errorCode: err.code
        })
      })
  }
  return (
    <>
      {/* prettier-ignore */}
      <section className="bg-light py-5">
        <div className="container px-5 my-5">
          <div className="text-center mb-5">
            <h1 className="fw-bolder">Generate QRcode</h1>
            <p className="lead fw-normal text-muted mb-0">
              In hurry? generate basic qrcode without any extra configuration!
            </p>
          </div>
          <div className="row gx-5 justify-content-center">
            {error && (
              <div className="alert alert-danger" role="alert">
                {error.errorCode} {error.errorMessage}
              </div>
            )}
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <input
                  disabled={loading}
                  type="text"
                  className="form-control custom-shadow"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-lg"
                  ref={qrValue}
                  required={true}
                  placeholder="https://yourwebsite.com"
                />
              </div>
              <div className="mb-3 d-grid gap-2 col-md-4 col-lg-3 mx-auto">
              <button 
                className="btn btn-dark btn-lg custom-shadow"
                type="submit"
                disabled={loading}>
                {loading && (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    />
                    &#x20; Loading...
                  </>
                )}
                {!loading && (
                  <span>
                    <i className="bi bi-qr-code"></i> Generate QR
                  </span>
                )}
                </button>
              </div>
            </form>            
          </div>
        </div>
        <QrGenOut data={data} status={ready} />
      </section>
      <div className="container px-5 my-5">
        <h3 className="text-center">USER GUIDE</h3>
        <div className="pt-5 col-lg-12">
          <div className="row gx-5 row-cols-1 row-cols-md-3">
            <div className="col mb-5 h-100">
              <div className="feature bg-dark bg-gradient text-white rounded-3 mb-3">
                <i className="bi bi-1-square"></i>
              </div>
              <h2 className="h5">Enter data</h2>
              <p className="mb-0">
                Enter any text that you want, such as URL, Facebook, Instagram, Twitter and
                more.(Maximum 7,089 characters)
              </p>
            </div>
            <div className="col mb-5 h-100">
              <div className="feature bg-dark bg-gradient text-white rounded-3 mb-3">
                <i className="bi bi-2-square"></i>
              </div>
              <h2 className="h5">Generate QR</h2>
              <p className="mb-0">
                Click the <code>Generate QR</code> button to generate your QR code. Need a custom
                design or functionality? Sign up now!
              </p>
            </div>
            <div className="col mb-5 mb-md-0 h-100">
              <div className="feature bg-dark bg-gradient text-white rounded-3 mb-3">
                <i className="bi bi-3-square"></i>
              </div>
              <h2 className="h5">Download</h2>
              <p className="mb-0">
                Download your genrated Qr code, default file type is <code>.PNG</code>
              </p>
            </div>
          </div>
        </div>
      </div>
      <section className="bg-light py-5">
        <div className="container px-5 my-5">
          <h3 className="text-center">ABOUT</h3>
        </div>
      </section>
      <Faq />
      <DansoWave />
    </>
  )
}

export default QrGen
